<template>
  <div>
    <b-overlay
      :show="changed && saving"
      no-wrap
      spinner-variant="primary"
    ></b-overlay>
    <b-row v-if="localUser">
      <b-col
        cols="6"
        offset="3"
        offset-sm="0"
        sm="4"
        md="2"
        class="text-center"
      >
        <b-img
          v-if="localUser.picture"
          :src="localUser.picture"
          fluid-grow
          rounded="circle"
        />
        <b-avatar v-else size="6rem" variant="light"></b-avatar>
        <!--b-button variant="outline-primary" size="sm" class="my-2"
              >Feltöltés</b-button
            -->
      </b-col>
      <b-col cols="12" sm="8" md="10">
        <b-form @reset.prevent="setLocalUser(null)" @submit.prevent="save">
          <b-form-group
            label="Név"
            label-for="name"
            description="Opcionális információ. Elrejtve csak az adminok és moderátorok férnek hozzá."
          >
            <b-input-group>
              <b-form-input
                id="name"
                v-model="localUser.name"
                placeholder="Írd be a neved"
                :readonly="readonly"
              ></b-form-input>
              <b-input-group-append is-text>
                <b-checkbox
                  v-model="localUser.namePublic"
                  switch
                  class="pointer"
                  :disabled="readonly"
                >
                  <b-icon
                    :icon="`eye${localUser.namePublic ? '' : '-slash'}`"
                  ></b-icon>
                </b-checkbox>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Szerep"
            label-for="role"
            description="Csak adminisztrátor módosíthatja"
          >
            <b-input-group>
              <b-form-select
                id="role"
                :disabled="
                  currentUser.role !== 'admin' || user.role === 'admin'
                "
                :options="[
                  { text: $t('admin'), value: 'admin' },
                  { text: $t('moderator'), value: 'moderator' },
                  { text: $t('user'), value: 'user' }
                ]"
                v-model="localUser.role"
              ></b-form-select>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Felhasználónév"
            label-for="userName"
            description="Egyedi felhasználóneved. Látható mindenki számára."
          >
            <b-input-group prepend="@">
              <b-form-input
                id="userName"
                v-model="localUser.userName"
                placeholder="Írd be a felhasználóneved"
                required
                :state="changed ? !validation.userName : null"
                :formatter="userNameFormatter"
                :readonly="readonly"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="!validation.userName">
              {{ validation.userName }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Discord ID" label-for="discordId">
            <b-input-group prepend="@">
              <b-form-input
                id="discordId"
                v-model="localUser.discordId"
                placeholder="Másold be a Discord-ról"
                trim
                :readonly="readonly"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  :disabled="!localUser.discordId"
                  :href="`https://discord.com/users/${localUser.discordId}`"
                  target="_blank"
                >
                  Csekkold
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-muted">
              Discord felhasználói azonosítód (123456789012345 formában). Ha
              megadod, a <strong>mitjátsszunk.online</strong> botok meg tudnak
              jelölni a szerverre küldött frissítésekben, valamint a
              felhasználók is tudnak neked üzenni.<br />
              <a
                href="https://techswift.org/2020/04/22/how-to-find-your-user-id-on-discord/"
                target="_blank"
                >Honnan szerezhetem meg az azonosítómat?</a
              >
            </small>
          </b-form-group>

          <b-form-group
            label="Email"
            label-for="email"
            description="Kötelező, nem módosítható információ. Elrejtve csak az adminok és moderátorok férnek hozzá."
          >
            <b-input-group>
              <b-form-input
                id="email"
                v-model="localUser.email"
                type="email"
                required
                readonly
              ></b-form-input>
              <b-input-group-append is-text>
                <b-checkbox
                  v-model="localUser.emailPublic"
                  switch
                  class="pointer"
                  :disabled="readonly"
                >
                  <b-icon
                    :icon="`eye${localUser.emailPublic ? '' : '-slash'}`"
                  ></b-icon>
                </b-checkbox>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <!--b-form-group
                label="Publikus információk"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  v-model="localUser.visibility"
                  :aria-describedby="ariaDescribedby"
                >
                  <b-form-checkbox value="name">Név</b-form-checkbox>
                  <b-form-checkbox value="email">Email</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group-->
          <template v-if="!readonly">
            <b-button
              type="submit"
              variant="primary"
              :disabled="!changed"
              class="mr-2"
              >Mentés</b-button
            >
            <b-button type="reset" variant="outline-danger" :disabled="!changed"
              >Mégse</b-button
            >
          </template>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import UserModuleMixin from "@/mixins/UserModuleMixin";
export default {
  name: "ProfileEditor",
  mixins: [UserModuleMixin],
  props: {
    user: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localUser: null,
      saving: false
    };
  },
  created() {
    this.setLocalUser();
  },
  computed: {
    validation() {
      let userName = "";
      if (
        this.userModuleItems.find(
          (user) =>
            user.uid !== this.localUser.uid &&
            user.userName === this.localUser.userName
        )
      )
        userName = "Ez a felhasználónév már foglalt";
      if (this.localUser.userName.trim().length === 0)
        userName = "Kötelező mező";

      return {
        userName
      };
    },
    changed() {
      return JSON.stringify(this.user) !== JSON.stringify(this.localUser);
    }
  },
  methods: {
    userNameFormatter(value) {
      return value
        .replace(/[^a-zA-Z-_.]/g, "")
        .toLowerCase()
        .trim();
    },
    setLocalUser(user) {
      this.localUser = user || this.user ? { ...(user || this.user) } : null;
    },
    async save() {
      if (Object.values(this.validation).find((val) => !!val)) {
        this.$bvToast.toast("Valami nem stimmel, ellenőrizd a mezőket.", {
          title: `Hiba`,
          variant: "danger",
          solid: true
        });
        return;
      }
      this.saving = true;
      try {
        await this.updateUser(this.localUser);
        this.$bvToast.toast("Felhasználó elmentve", {
          title: `Siker`,
          variant: "success",
          solid: true
        });
      } catch (error) {
        console.log(error);
        this.$bvToast.toast("Nem sikerült a mentés", {
          title: `Hiba`,
          variant: "danger",
          solid: true
        });
      } finally {
        this.saving = false;
      }
    }
  },
  watch: {
    user(user) {
      this.setLocalUser(user);
    }
  }
};
</script>
