<template>
  <div class="game-wrapper">
    <b-card no-body>
      <b-card-header
        class="d-flex align-items-center no-focus"
        v-b-toggle="_uid + '_' + game.id"
      >
        <strong class="mr-2">
          {{ name }}
        </strong>
        <div class="mr-auto"></div>
        <b-badge v-if="badgeContent" variant="primary" class="mr-3">
          {{ badgeContent }}
        </b-badge>
        <b-button
          v-if="game.content"
          variant="link"
          size="sm"
          :to="{
            name: 'ViewGame',
            params: { gamePath: game.content.path }
          }"
        >
          <b-icon-box-arrow-up-right />
        </b-button>

        <b-button
          variant="text"
          class="d-none d-sm-block text-primary"
          size="sm"
        >
          <b-icon-chevron-up class="when-open" />
          <b-icon-chevron-down class="when-closed" />
        </b-button>
      </b-card-header>
      <b-collapse
        :visible="!closedPanel && !!badgeContent"
        :id="_uid + '_' + game.id"
      >
        <b-list-group flush>
          <b-list-group-item
            v-for="cr in game.changeRequests"
            :key="_uid + '_' + cr.id"
          >
            <div
              class="d-flex align-items-center no-focus"
              v-b-toggle="_uid + '_' + cr.id"
            >
              <user :uid="cr.authorId" />
              <small class="ml-1 d-none d-md-inline">
                javaslata
              </small>
              <b-icon :icon="icon(cr)" :class="`mx-2 text-${color(cr)}`" />
              <small class="ml-auto text-muted text-right">
                {{ displayDate(cr.createdAt) }}
              </small>
              <b-button
                variant="text"
                class="ml-2 d-none d-sm-block text-primary"
                size="sm"
              >
                <b-icon-chevron-up class="when-open" />
                <b-icon-chevron-down class="when-closed" />
              </b-button>
            </div>
            <b-collapse :visible="!cr.moderatorId" :id="_uid + '_' + cr.id">
              <request :game="game" :request="cr" />
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import GameModuleMixin from "../mixins/GameModuleMixin";
import UserModuleMixin from "../mixins/UserModuleMixin";
import Request from "./Request.vue";
import User from "./User.vue";
import displayDate from "@/utils/displayDate";

export default {
  name: "GameRequests",
  props: {
    game: {
      type: Object,
      required: true
    },
    closedPanel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    User,
    Request
  },
  mixins: [GameModuleMixin, UserModuleMixin],
  computed: {
    name() {
      if (this.game.content) return this.game.content.name;
      const nameCr = this.game.changeRequests.find(
        (cr) => !!cr.fields.find((f) => f.name === "name")
      );
      if (nameCr) return nameCr.fields.find((f) => f.name === "name").valueTo;
      return "névtelen";
    },
    badgeContent() {
      const pending = this.game.changeRequests.filter((cr) => !cr.moderatorId);
      if (!pending.length) return "";
      if (this.game.changeRequests.length === pending.length)
        return this.$t("new");
      return pending.length;
    }
  },
  methods: {
    status(cr) {
      if (!!cr.approvedAt && !cr.deniedAt) return "approved";
      if (!cr.approvedAt && !!cr.deniedAt) return "denied";
      if (!!cr.approvedAt && !!cr.deniedAt)
        return [
          ["approved", new Date(cr.approvedAt).getTime()],
          ["denied", new Date(cr.deniedAt).getTime()]
        ].sort((a, b) => b[1] - a[1])[0][0];
      return "pending";
    },
    icon(cr) {
      switch (this.status(cr)) {
        case "approved":
          return "check2-circle";
        case "denied":
          return "x-circle";
        default:
          return "question-circle";
      }
    },
    color(cr) {
      switch (this.status(cr)) {
        case "approved":
          return "success";
        case "denied":
          return "danger";
        default:
          return "primary";
      }
    },
    displayDate
  }
};
</script>
<style lang="scss" scoped>
.game-wrapper,
.request-card {
  & + & {
    margin-top: 16px;
  }
}
</style>
