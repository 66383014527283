<template>
  <div class="field-value">
    <component
      :is="fieldOptions.component"
      v-bind="fieldOptions"
      class="field-content"
    >
      {{ fieldOptions.formatter ? fieldOptions.formatter(value) : value }}
    </component>
  </div>
</template>
<script>
import displayUrl from "@/utils/displayUrl";
import RichtextEditor from "../RichtextEditor.vue";
import PlayerCountFieldValue from "./PlayerCountFieldValue.vue";
import PricingFieldValue from "./PricingFieldValue.vue";
import ExternalLinksFieldValue from "./ExternalLinksFieldValue.vue";
import PostFixFieldValue from "./PostFixFieldValue.vue";
export default {
  components: {
    RichtextEditor,
    PlayerCountFieldValue,
    PricingFieldValue,
    ExternalLinksFieldValue,
    PostFixFieldValue
  },
  props: {
    value: {
      required: true
    },
    name: {
      required: true
    },
    dir: {
      required: true,
      validator: function(v) {
        return ["from", "to"].indexOf(v) !== -1;
      }
    }
  },
  computed: {
    fieldOptions() {
      // empty field
      if (this.value === undefined || this.value === null)
        return {
          component: "p",
          class: "m-0 text-center empty"
        };

      // link
      if (this.name === "url")
        return {
          component: "b-link",
          formatter: displayUrl,
          href: this.value,
          target: "_blank",
          class: "card-link"
        };

      // description
      if (this.name === "description")
        return {
          component: "richtext-editor",
          readonly: true,
          value: this.value
        };

      // playerCount
      if (this.name === "playerCount")
        return {
          component: "player-count-field-value",
          value: this.value
        };

      // playTime
      if (this.name === "playTime")
        return {
          component: "post-fix-field-value",
          value: this.value,
          postfix: "perc"
        };

      // ageLimit
      if (this.name === "ageLimit")
        return {
          component: "post-fix-field-value",
          value: this.value,
          postfix: "év"
        };

      // pricing
      if (this.name === "pricing")
        return {
          component: "pricing-field-value",
          value: this.value
        };

      // boolean
      if (this.name === "hunLocalized")
        return {
          component: "span",
          formatter: (v) => this.$t(v ? "yes" : "no")
        };

      // trilean
      if (
        ["registeredUserNeeded", "groupNeeded", "meetNeeded"].includes(
          this.name
        )
      )
        return {
          component: "span",
          formatter: (v) => this.$t(v)
        };

      // stringlist
      if (["platforms", "pro", "contra", "tags"].includes(this.name))
        return {
          component: "b-table",
          items: this.value.map((row) => ({
            row
          })),
          borderless: true,
          //hover: true,
          small: true,
          "thead-class": "d-none",
          "tbody-tr-class": "text-primary stringlist-item",
          class: "m-0"
        };

      // externalLinks
      if (this.name === "externalLinks")
        return {
          component: "external-links-field-value",
          value: this.value
        };

      // simple text, number
      return {
        component: "span"
      };
    }
  }
};
</script>
<style lang="scss">
.field-value {
  padding: 8px 12px;
  background-color: #f0f5f5;
  border-radius: 16px;
  .field-content {
    &.empty::before {
      content: "üres";
      opacity: 0.4;
    }
  }
  .stringlist-item > td {
    padding: 0px;
    &::before {
      content: "•";
      margin: 0 4px;
    }
  }
}
</style>
