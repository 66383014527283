<template>
  <div class="mb-3">
    <h5 :class="{ conflict: !!conflict }">
      <b-icon-exclamation-circle v-if="!!conflict" class="mr-1" />
      <strong>{{ $t(field.name) }}</strong>
    </h5>
    <div class="d-block d-lg-flex align-items-top">
      <div class="field-col">
        <field-value :name="field.name" :value="field.valueFrom" dir="from" />
      </div>
      <div class="sep-col d-flex">
        <b-icon-arrow-right-square-fill
          class="d-none d-lg-block mx-auto"
          variant="primary"
        />
        <b-icon-arrow-down-square-fill
          class="d-block d-lg-none mx-auto my-2"
          variant="primary"
        />
      </div>
      <div class="field-col">
        <field-value :name="field.name" :value="field.valueTo" dir="to" />
      </div>
    </div>
    <div v-if="!!conflict">
      <strong class="d-block mt-2 mb-1" :class="{ conflict: !!conflict }"
        >Jelenlegi érték</strong
      >
      <field-value :name="field.name" :value="conflict.current" dir="to" />
    </div>
  </div>
</template>
<script>
import FieldValue from "./FieldValue.vue";
export default {
  components: { FieldValue },
  name: "Field",
  props: {
    field: {
      required: true
    },
    conflict: {
      type: Object
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

h5.conflict {
  color: $danger;
}
.field-col {
  flex-basis: 48%;
  flex-shrink: 0;
  word-break: break-word;

  @media (min-width: 62em) {
    max-width: 48%;
  }
}
.sep-col {
  flex-basis: 4%;
  flex-shrink: 0;
  height: 40px;
  display: flex;
  align-items: center;
}
</style>
